import request from '@/request'

export const getEmployeeList = (data = {}) => {
  return request({
    url: '/admin/employee/get_employee_list',
    method: 'get',
    params: data
  })
}
export const updateEmployee = (data = {}) => {
return request({
  url: '/admin/employee/update_employee',
  method: 'post',
  data
})
}
export const removeEmployee = (data = {}) => {
return request({
  url: '/admin/employee/remove_employee',
  method: 'post',
  data
})
}