<template>
    <div class="flex_layout inner">
        <div class="flex_top">
            <div class="query flex">
                <a-space>
                    <div class="query_box">
                        <a-input v-model:value="query.employee_name" placeholder="Empolyee name" />
                    </div>
                    <div class="query_box">
                        <a-space>
                            <a-button @click="queryByDep(0)">
                              <template #icon><component :is="$antIcons['SearchOutlined']" /></template>
                            </a-button>
                            <a-button class="primary btn" @click="update('add')">Add</a-button>
                        </a-space>
                    </div>
                </a-space>
            </div>
        </div>
		<div class="tabs flex">
            <div class="tab_label" @click="queryByDep(0)" :class="{on:0===currentDep}">All</div>
            <div class="tab_label"  v-for="(dep,i) in depList" @click="queryByDep(dep.dep_id)" v-bind:class="{on:i+1===currentDep}">{{dep.department_name}}</div>
		</div>
        <div class="flex_body">
            <a-spin :spinning="spinning">
                <a-table :dataSource="dataSource" :columns="columns" :pagination="false">
                    <template #role="{record}">
                        <span>{{getRole(record.role_id)}}</span>
                    </template>
                    <template #action="{record}">
                        <a-space>
                            <a-button class="primary btn" @click="update('edit', record)" size="small" type="primary">Edit</a-button>
                            <a-popconfirm
                                title="Are you sure delete this?"
                                ok-text="Yes"
                                cancel-text="No"
                                @confirm="() => remove(record.emp_id)"
                            >
                                <a-button danger size="small">Delete</a-button>
                            </a-popconfirm>
                        </a-space>
                    </template>
                </a-table>
            </a-spin>
        </div>
        <div class="flex_bottom"> 
            <a-pagination size="small" @change="changePage" v-model:current="query.page" :total="total" show-less-items />
        </div>
        <a-drawer
            title="Employee"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
                   <a-spin :spinning="spinning">
                    <div class="form">
                        <div class="form_title">Employee</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Employee name">
                                <a-input v-model:value="formData.username" placeholder="Employee name" />
                            </a-form-item>
                            <a-form-item label="Employee password">
                                <a-input type="password" v-model:value="formData.password" placeholder="Employee password" />
                            </a-form-item>
                            <a-form-item label="Department">
                                <a-select
                                    ref="select"
                                    v-model:value="formData.dep_id"
                                    :options="depList"
                                    placeholder="Select department"
                                >
                                </a-select>
                            </a-form-item>
                            <a-form-item label="Employee role">
                                <a-select
                                    ref="select"
                                    v-model:value="formData.role_id"
                                    :options="roleList"
                                    placeholder="Select role"
                                >
                                </a-select>
                            </a-form-item>
                            <a-form-item label="Actions">
                                <div class="actions">
                                    <div class="actions_cell" v-for="(action, idx) in formData.actions">
                                        <div class="actions_name">{{action.name}}</div>
                                        <div class="actions_list">
                                            <a-row :gutter="10">
                                                <a-col :span="12" v-for="(check, idx) in action.actions" :key="idx">
                                                    <a-checkbox v-model:checked="check.value">{{check.action_name}}</a-checkbox>
                                                </a-col>
                                            </a-row>
                                        </div>
                                    </div>
                                </div>
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
                                <a-button type="primary" @click="updateEmployeeHandler">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                   </a-spin>
               </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    // {
    //     title: 'UUID',
    //     dataIndex: 'uuid',
    //     key: 'uuid',
    // },
    {
        title: 'Employee name',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Department name',
        dataIndex: 'department_name',
        key: 'department_name',
    },
    {
        title: 'Role',
        slots: { customRender: 'role' },
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import { message } from 'ant-design-vue'
import tabMixins from '@/mixins/tabMixins'
import { roleList } from '@/config/const'
import { getEmployeeList, updateEmployee, removeEmployee } from '@/apis/employee'
import { getDepartmentList } from '@/apis/department'
import { getPermissions } from '@/apis/system'
export default {
    name: 'Employee',
    mixins: [tabMixins],
    data(){
        return {
            dataSource: [],
            columns,
            query: {
                employee_name: '',
                page: 1,
                page_size: 10  
            },
            roleList,
            depList: [],
            actions: [],
            formData: {},
            total: 1,
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            spinning: false,
            visible: false,
            currentDep:0,
        }
    },
    methods: {
        async update(actionType, empInfo){
            if(actionType == 'add'){
                this.initParams()
                this.setActions()
            }
            if(actionType == 'edit'){
                this.formData = empInfo
            }
            this.formData.action_type = actionType
            this.visible = true
        },
        async updateEmployeeHandler(){
            const username = this.formData.username
            const password = this.formData.password
            if(username == '') return message.error('Please input employee name')
            if(password == '') return message.error('Please input employee password')
            this.spinning = true
            const res = await updateEmployee(this.formData)
            this.spinning = false
            if(res){
                message.success('success')
                this.visible = false
                this.getEmployeeListHandler()
            }
        },
        async remove(emp_id){
            this.spinning = true
            const res = await removeEmployee({emp_id})
            this.spinning = false
            if(res){
                this.getEmployeeListHandler()
            } 
        },
        onClose(){
            this.visible = false
        },
        async getDepartmentListHandler(){
            const res = await getDepartmentList()
            if(res){
                this.depList = res.list.map(r => {
                    r.value = r.dep_id
                    r.label = r.department_name
                    return r
                })
                this.formData.dep_id = this.depList[0].dep_id || undefined
            }
        },
        async getEmployeeListHandler(){
            this.spinning = true
            const res = await getEmployeeList(this.query)
            this.spinning = false
            if(res){
                this.dataSource = res.list.rows
                this.total = res.list.count
            }
        },
        async getPermissionsHandler(){
            const res = await getPermissions()
            this.actions = res.list.map(r => {
                r.value = false
                return r
            })
        },
		async queryByDep(dep_id){
            this.currentDep=dep_id
            this.query.dep_id=dep_id
            const res = await getEmployeeList(this.query)
            if(res){
                this.dataSource = res.list.rows
                this.total = res.list.count
            }
			// const res = await getDepartmentList({'dep_id': dep_id})
		},
        initParams(){
            const role_id = this.roleList[0].id
            this.formData = {
                dep_id: this.depList.length == 0 ? undefined : this.depList[0].dep_id,
                emp_id: undefined,
                username: '',
                password: '',
                role_id,
                actions: [],
                state: 0
            }
        },
        setActions(){
            const actions = [
                {
                    name: 'Task',
                    type: 'task', 
                    actions: JSON.parse(JSON.stringify(this.actions)),
                }
            ]
            this.formData.actions = actions
        },
        changePage(page){
            this.query.page = page
            this.getEmployeeListHandler()
        }
    },
    computed: {
        getRole(){
            return role_id => {
                return this.roleList.filter(r => r.id == role_id)[0].name
            }
        }
    },
    created(){
        this.initParams()
        this.getDepartmentListHandler()
        this.getEmployeeListHandler()
        this.getPermissionsHandler()
    }
}
</script>
<style lang="stylus" scoped>
.actions_cell
    border 1px dashed #eee
    margin-bottom 10px
    .actions_name
        border-bottom 1px dashed #eee
        padding-left 10px
        line-height 30px
    .actions_list
        padding 5px 10px
.flex_depart{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between; 
	text-align: center;
}
</style>